import React, { useContext, useEffect, useState } from 'react'
/* import { useHistory } from "react-router-dom"; */

import { Modal, ModalHeader, ModalBody, Collapse, Button, CardBody, Card} from 'reactstrap';
import { Form, ModalFooter } from 'react-bootstrap';
/* DATA */
import { UserContext } from "../../../../context/userContext"
import { CandidateContext } from "../../../../context/CandidateContext";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import pdfFile from '../../../../assets/pdf/ReferenciaBancaria.pdf'

import { faAngleDown, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { KnowingIfa } from './KnowingIfa';
import { PrintRowData } from './PrintRowData';

import { useSnackbar } from 'react-simple-snackbar'

import { API } from "../../../../http-common";
import {API_URL, PROSPECT_STATUS,GRUPO_STATUS,GROUP, LEGAJO_COMITENTE_URL, LEGAJO_CUOTAPARTISTA_URL} from '../../../../constants';

import { ValidateInput } from '../../../../hooks/ValidateInput';
import * as list from '../../../../helpers/ListOptions';
import {parseRelationshipsData} from "../../../../helpers/common";

export const DataPersonalApplicant = ({knowingIfaData}) => {

    //TODO: ESPERAR DE LA API LA LISTA DE F.A ASIGNADOS Y DEL GRUPO COMERCIAL, LOS CUALES DEBEN VENIR CON LAS PROPIEDAS: id, nombre, selected

    /* DATA DEL CANDIDATO EN CONTEXTO */
    const { candidateData, setCandidateData } = useContext( CandidateContext )

    /* DATA DEL USUARIO EN CONTEXTO */
    const { userData, setUserData } = useContext( UserContext )

    const [ getDataApi, setGetDataApi ] = useState( true )

    const [submit, setSubmit] = useState(false)
    const [working, setWorking] = useState(false)

    const [modalInfo, setModalInfo] = useState(false)
    const [modalText, setModalText] = useState("")

    const [grupoComercial, setGrupoComercial] = useState("")
    const [grupoComercialList, setGrupoComercialList] = useState([])
    const [asesor, setAsesor] = useState("")
    const [asesorList, setAsesorList] = useState([])

    const [lastValueAsesor, setLastValueAsesor] = useState("")
    const [lastValueGrupoComercial, setLastValueGrupoComercial] = useState("")
    const [ccbu, setCcbu] = useState(null)

    const checkStatus = [PROSPECT_STATUS.PRE_CANDIDATO, PROSPECT_STATUS.CONTACTADO].includes(candidateData.prospect_status) || (candidateData.prospect_status === PROSPECT_STATUS.SC_CANDIDATO && GROUP.EQ_SOPORTE.includes(userData.role_id))
    const checkDocument = (GROUP.EQ_DIRECTORES.includes(userData.role_id) && GRUPO_STATUS.EQ_COMERCIAL.includes(candidateData.prospect_status)) ||
        (GROUP.EQ_DIRECTORES.includes(userData.role_id) && PROSPECT_STATUS.CONTACTADO === candidateData.prospect_status) ||
        (GROUP.EQ_SOPORTE.includes(userData.role_id) && GRUPO_STATUS.EQ_SOPORTE.includes(candidateData.prospect_status));
    const CanEditSensityData = (GROUP.EQ_SOPORTE.includes(userData.role_id) )
    // const backOfficeUrl = process.env.NODE_ENV === 'production'
    //     ? 'https://users.balanz.com/backoffice/'
    //     : 'https://devusers.balanz.com/backoffice/';

    /* OPTIONS SNACK BAR ERROR */
    const optionsError = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }
    /* OPTIONS SNACK BAR SUCCESS */
    const optionsSuccess = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-green)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }
    const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
    const [openSnackbarError] = useSnackbar(optionsError)

    /* IS OPEN MODAL */
    const [isOpen, setIsOpen] = useState(true);

    /* DEF DE PLUGIN PDF */
    const [PdfModalOpen, setPdfModalOpen] = useState(false);
    const [file] = useState(pdfFile);
    const [numPages, setNumPages] = useState(null);
    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    /* referred: candidateData.referred ? candidateData.referred : '',
        commercial_group: candidateData.commercial_group ? candidateData.commercial_group : '',
        adviser: candidateData.adviser ? candidateData.adviser : '',

        cliente_number: candidateData.cliente_number ? candidateData.cliente_number : '',
        is_balanz: candidateData.is_balanz ? candidateData.is_balanz : '',

        business_unit: candidateData.business_unit ? candidateData.business_unit : '',
        shareolde: candidateData.shareolde ? candidateData.shareolde : '',

        other_relationships: candidateData.other_relationships ? candidateData.other_relationships : '',

        application_date: candidateData.application_date ? candidateData.application_date : '',

        complete_name: candidateData.complete_name ? candidateData.complete_name : '',
        business_name: candidateData.business_name ? candidateData.business_name : '',
        document: candidateData.document ? candidateData.document : '',

        business_type: candidateData.business_type ? candidateData.business_type : '-',

        bank_name: candidateData.bank_name ? candidateData.bank_name : '',
        cbu: candidateData.cbu ? candidateData.cbu : '',
        account_type: candidateData.account_type ? candidateData.account_type : '',

        rrss_fb: candidateData.rrss_fb ? candidateData.rrss_fb : '',
        rrss_lk: candidateData.rrss_lk ? candidateData.rrss_lk : '',
        rrss_tw: candidateData.rrss_tw ? candidateData.rrss_tw : '',
        rrss_ig: candidateData.rrss_ig ? candidateData.rrss_ig : '',

    */



    const errorDatosGeneralesInitialState = {
        date_birth: '',
        lnaci: '',
        nationality: '',
        gender: '',
        marital_status: '',
        job_post: '',
        phone_number: '',
        mobil_number: '',
        address: '',
        zip_code: '',
        location: '',
        province: '',
        email: '',
        document: '',
        complete_name: '',
        cuit: '',
        pos_tax_profit: '',
        pos_front_iva: '',
        withholding_agent: '',
        perception_agent: '',
        certificate_non_retention: '',
        type_activity: '',
        business: '',
        income_tax_situation: '',
        registration_number: '',
        regime_retention_income_tax: '',
        constancia_uif_so: '',
        ddjj_so: '',
        suitable: '',
        agente_cnv_aagi_ap: '',
        enrollment_aagi: '',
        pep: '',
        pep_reasons: '',
        is_incubator: '',
        suitability_exam: '',
        bank_name: '',
        cbu: '',
        account_type: '',
        rrss_fb: '',
        rrss_lk: '',
        rrss_tw: '',
        rrss_ig: '',
    }

    const [errorDatosGenerales, setErrorDatosGenerales ] = useState(errorDatosGeneralesInitialState)
    const [datosPersonales, setDatosPersonales] = useState('')

    let datosInitialState = {}

    /* SETEAR LOS VALORES INICIALES DEL FORMULARIO */
    const seDatosPersonales = async () => {
        setGetDataApi( false )

        let phone_country_code = ""
        let phone_area = ""
        let phone_number = ""

        let phone2_country_code = ""
        let phone2_area = ""
        let phone2_number = ""

        if( candidateData.phone_number ) {
            phone_country_code = candidateData.phone_country_code
            phone_area = candidateData.phone_area
            phone_number = candidateData.phone_number
        }

        if( candidateData.phone2_number ) {
            phone2_country_code = candidateData.phone2_country_code
            phone2_area = candidateData.phone2_area
            phone2_number = candidateData.phone2_number
        }

        let cuit = "";

        if( candidateData.cuit ){
            cuit = candidateData.cuit
        }

        /* VALORES INICIALES DEL FORMULARIO DE DATOS PERSONALES */
        const datosPersonalesinitalState = {

            date_birth: candidateData.date_birth ? candidateData.date_birth : '',
            lnaci: candidateData.lnaci ? candidateData.lnaci : '',
            nationality: candidateData.nationality ? candidateData.nationality : '',

            gender: candidateData.gender ? candidateData.gender : '',

            marital_status: candidateData.marital_status ? candidateData.marital_status : '',
            job_post: candidateData.job_post ? candidateData.job_post : '',

            /* PHONE PERSONAL */
            phone_country_code: phone_country_code,
            phone_area: phone_area,
            phone_number: phone_number,

            /* PHONE MOBILE */
            phone2_country_code: phone2_country_code,
            phone2_area: phone2_area,
            phone2_number: phone2_number,

            address: candidateData.address ? candidateData.address : '',
            zip_code: candidateData.zip_code ? candidateData.zip_code : '',
            location: candidateData.location ? candidateData.location : '',
            province: candidateData.province ? candidateData.province : '',

            email: candidateData.email ? candidateData.email : '',
            document: candidateData.document ? candidateData.document : '',
            complete_name: candidateData.complete_name ? candidateData.complete_name : '',
            cuit: cuit,
            pos_tax_profit: candidateData.pos_tax_profit ? candidateData.pos_tax_profit : '',
            pos_front_iva: candidateData.pos_front_iva ? candidateData.pos_front_iva : '',

            withholding_agent: candidateData.withholding_agent ? candidateData.withholding_agent : '',
            perception_agent: candidateData.perception_agent ? candidateData.perception_agent : '',
            certificate_non_retention: candidateData.certificate_non_retention ? candidateData.certificate_non_retention : '',
            type_activity: candidateData.type_activity ? candidateData.type_activity : 0,
            business: candidateData.business ? candidateData.business : '',

            income_tax_situation: candidateData.income_tax_situation ? candidateData.income_tax_situation : '',
            registration_number: candidateData.registration_number ? candidateData.registration_number : '',
            regime_retention_income_tax: candidateData.regime_retention_income_tax ? candidateData.regime_retention_income_tax : '',

            constancia_uif_so: candidateData.constancia_uif_so ? candidateData.constancia_uif_so : '',
            ddjj_so: candidateData.ddjj_so ? candidateData.ddjj_so : 0,

            suitable:  candidateData.suitable ? candidateData.suitable : '',

            agente_cnv_aagi_ap: candidateData.agente_cnv_aagi_ap ? candidateData.agente_cnv_aagi_ap : '',
            enrollment_aagi: candidateData.enrollment_aagi ? candidateData.enrollment_aagi : '',
            pep: candidateData.pep > 0 ? candidateData.pep : 0,
            pep_reasons: candidateData.pep_reasons !== "null" ? candidateData.pep_reasons : "",
            is_incubator: candidateData.is_incubator ? candidateData.is_incubator : 0,
            suitability_exam: candidateData.suitability_exam ? candidateData.suitability_exam: 0,
            document_front: candidateData.document_front !== "" ? candidateData.document_front : "",
            document_back: candidateData.document_back !== "" ? candidateData.document_back : "",
        }

        const propspect_ifa = {
            bank_name: candidateData.bank_name ? candidateData.bank_name : '',
            cbu: candidateData.cbu ? candidateData.cbu : '',
            account_type: candidateData.account_type ? candidateData.account_type : '',
            rrss_fb: candidateData.rrss_fb ? candidateData.rrss_fb : '',
            rrss_lk: candidateData.rrss_lk ? candidateData.rrss_lk : '',
            rrss_tw: candidateData.rrss_tw ? candidateData.rrss_tw : '',
            rrss_ig: candidateData.rrss_ig ? candidateData.rrss_ig : '',
        }

        if( candidateData.prospect_status > 29) {
            datosInitialState = {
                ...datosPersonalesinitalState,
                ...propspect_ifa
            }

        } else {
            datosInitialState = { ...datosPersonalesinitalState }
        }

        setDatosPersonales( datosInitialState )
    }

    useEffect(() => {
        setTimeout(() => {
            let enrollment_aagi = document.querySelectorAll("#enrollment_aagi")
            let pep_reasons = document.querySelectorAll("#pep_reasons")
            let editButton = document.querySelectorAll("#edit")
            if(editButton.length){
                if(enrollment_aagi.length > 0) {
                    enrollment_aagi[0].setAttribute("disabled", true)
                }
                if(pep_reasons.length > 0 && editButton[0].classList.contains("hidden") === false) {
                    pep_reasons[0].setAttribute("disabled", true)
                }
            }
        }, 2000);
    }, [])

    const [relationshipsString, setRelationshipsString] = useState('');

    useEffect(() => {
        setRelationshipsString(parseRelationshipsData(candidateData.relationships));

        if ( candidateData.id && getDataApi) {
            ( async () => {
                await seDatosPersonales()
            })();
        }
    },[candidateData])

    const toggleEdit = () => {
        let campos = document.querySelectorAll('form#data_postulant table tbody input, form#data_postulant table tbody select')
        campos.forEach( (campo) => campo.toggleAttribute('disabled'));

        let buttos_action = document.querySelector('#buttons_actions_form');
        buttos_action.classList.toggle('hidden');

        let button_edit = document.querySelector('#edit');
        button_edit.classList.toggle('hidden')

        setErrorDatosGenerales( errorDatosGeneralesInitialState );

        ( async () => {
            await seDatosPersonales()
        })();
    }

    const uploadFrontDoc = (async (e) => {
        //debugger
        const dataForm = new FormData();
        dataForm.append("document_front", e.target.files[0])
        API.post(`/prospects/document/front/${candidateData.id}`, dataForm, { timeout: 10000 }).then(res => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    })

    const uploadBackDoc = (async (e) => {
        //debugger
        const dataForm = new FormData();
        dataForm.append("document_back", e.target.files[0])
        API.post(`/prospects/document/back/${candidateData.id}`, dataForm, { timeout: 10000 }).then(res => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    })

    const saveDocs = (e, type) => {
        const dataForm = new FormData();
        for(let i = 0; i < e.target.files.length; i++) {
            dataForm.append(type, e.target.files[i])
        }
        API.post(`/prospects/uploads/${type}/${type}/${candidateData.id}`, dataForm, { timeout: 10000 }).then(res => {
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })

    }

    const deleteDocs = (type) => {
        API.post(`prospects/uploads/delete/${type}/${candidateData.id}`)
            .then(() => {window.location.reload()})
    }

    const changeCommercialManager = (id) => {
        setLastValueGrupoComercial(id)
        setModalText("Se cambiara el grupo comercial, desea continuar?")
        setModalInfo(true)
    };
    const confirmGrupoComercial = async () =>{
        setModalInfo(false)
        setGrupoComercial(lastValueGrupoComercial)
        setLastValueGrupoComercial("")
        await API.post(`prospects/change_director/${candidateData.id}/${lastValueGrupoComercial}`)
            .then((res) => {
                //console.log(res, "log")
                window.history.back();
                openSnackbarSuccess("Grupo Comercial cambiado")
            })
            .catch((err) => {
            openSnackbarError("hubo un error al guardar intentelo nuevamente")
            console.log(err);
            });
    }

    // const roleCanChange2 = [ROLE_EQ_COMERCIAL_LIDER, ROLE_EQ_COMERCIAL_ASISTENTE, ROLE_ALL].includes(userData.role_id);
    const roleCanChange = GROUP.EQ_DIRECTORES.includes(userData.role_id) && candidateData.prospect_status !== PROSPECT_STATUS.SC_CANDIDATO;

    const ableToChange = () => {
        let status = candidateData.prospect_status;
        let rechazado = false;
        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            rechazado = true
        }
        return roleCanChange && rechazado === false ? false : true
    }

    const ableToChangeAdviser = () => (roleCanChange && candidateData.prospect_status !== 49 ) ? false : true

    const changeCommercialAdviser = (id) => {
        //alert(id)
        setLastValueAsesor(id)
        setModalText("Se cambiara el asesor comercial, desea continuar?")
        setModalInfo(true)
    };

    const confirmAsesor = async () =>{
        setModalInfo(false)
        setAsesor(lastValueAsesor)
        setLastValueAsesor("")

        await API.post(`prospects/change_adviser/${candidateData.id}/${lastValueAsesor}`)
            .then((res) => {
                console.log("confirmAsesor then => ", res.data)
                window.history.back();
                openSnackbarSuccess("Asesor Comercial cambiado")
            })
            .catch((err) => {
            console.log(err);
            });
    }

    const handleChangeTableInput = async (event, typeOfPermitido) => {
        const { name, value, type } = event.target;
        let value__ = value;
        let validation = false;
    
        if (type !== 'date') {
            if (name === 'cuit') {
                if (value.length < 11) {
                    validation = false;
                    setErrorDatosGenerales(prevDataError => ({
                        ...prevDataError,
                        [name]: "El CUIT debe tener 11 caracteres"
                    }));
                } else if (value.length === 11) {
                    validation = ValidateInput(value,'cuit');
                    if (!validation) {
                        setErrorDatosGenerales(prevDataError => ({
                            ...prevDataError,
                            [name]: "CUIT inválido"
                        }));
                    }
                }
            } else {
                validation = await handleValidateInput(event, typeOfPermitido);
            }
        }
    
        let checked;
    
        if (name === "ddjj_so") {
            checked = event.target.checked;
            value__ = checked === true ? 2 : 1;
        }
        if (name === "is_incubator") {
            checked = event.target.checked;
            value__ = checked === true ? 0 : 1;
        }
        if (name === "suitability_exam") {
            checked = event.target.checked;
            value__ = checked === true ? 0 : 1;
        } else if (name === "pep") {
            if (Number(value) === 1) {
                setDatosPersonales(prevDatos => ({
                    ...prevDatos,
                    "pep_reasons": "null"
                }));
            }
            value__ = Number(value);
            console.log("pep", value__);
        } else if (name === "pep_reasons") {
            value__ = value;
        } else if (name === "agente_cnv_aagi_ap" && Number(value) === 1) {
            setDatosPersonales(prevDatos => ({
                ...prevDatos,
                "enrollment_aagi": "0"
            }));
        } else if (name === "document_front") {
            console.log(value, "doc_front");
            //value__ = value[0]
        }
    
        setDatosPersonales(prevDatos => ({
            ...prevDatos,
            [name]: value__
        }));
    
        if (validation) {
            setErrorDatosGenerales(prevDataError => ({
                ...prevDataError,
                [name]: ""
            }));
        }
    };

    const handleDocumentChange = (event) => {
        const {id, value} = event.target
        let validation = false
        if(id === "document"){
            validation = ValidateInput(value, "document")
        }
        if ( validation ) {
            setErrorDatosGenerales( prevDataError => ({
                ...prevDataError,
                [id]: ""
            }))
        }
        
    }
    const handleValidateInput = async ( event, typeOfPermitido ) => {
        const { name, value, nodeName } = event.target

        //console.log(`event.target`, nodeName)

        const checkInput = ValidateInput(value, typeOfPermitido)

        let validation = true

        if(checkInput === false && name !== "ddjj_so" && nodeName === "INPUT") {
            //console.log("name: ", name, " error")
            setErrorDatosGenerales( prevDataError => ({
                ...prevDataError,
                [name]: "valor inválido"
            }))

            validation = false

        } else if(checkInput === true) {
            setErrorDatosGenerales( prevDataError => ({
                ...prevDataError,
                [name]: ""
            }))
            validation = true
        }

        return validation
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setSubmit( checkInputs() )
        //console.log(`datosPersonales`, datosPersonales)
    }

    //console.log(`errorDatosGenerales`, errorDatosGenerales)

    const checkInputs = () => {
        let success = true;
        for( const [clave, valor] of Object.entries(datosPersonales) ) {
            if( clave === "rrss_fb" || clave === "rrss_tw" || clave === "rrss_lk" || clave === "rrss_ig") {
                continue
            }
            else if( clave === "cuit" ) {

                continue

            }
            
            else if( clave === "document" ) {
                continue
            }
            else if(clave === "registration_number"){
                continue

            } else if( clave === "cbu" && valor.length !== 22 ) {
                setErrorDatosGenerales( prevDatos => ({
                    ...prevDatos,
                    [clave]: "la longitud debe ser 22 caracteres numéricos"
                }))
                openSnackbarError('! CBU inválido !')
                document.querySelector('[name="cbu"]').focus();
                success = false

            } else if ( clave === "bank_name" && valor.length < 3 ) {
                setErrorDatosGenerales( prevDatos => ({
                    ...prevDatos,
                    [clave]: "la longitud debe ser, al menos, mayor a 2 caracteres"
                }))
                openSnackbarError('! Banco inválido !')
                document.querySelector('[name="bank_name"]').focus();
                success = false
            } else if ( clave === "account_type" && valor.length < 1 ) {
                setErrorDatosGenerales( prevDatos => ({
                    ...prevDatos,
                    [clave]: "Tipo de cuenta inválida, seleccione desde la lista"
                }))
                openSnackbarError('! Tipo de cuenta  !')
                document.querySelector('[name="account_type"]').focus();
                success = false
            }else if ( clave === 'business' && (valor === '' || valor.length <= 0) ){
                setErrorDatosGenerales( prevDatos => ({
                    ...prevDatos,
                    [clave]: "El campo industria es obligatorio"
                }))
                openSnackbarError('! Industria  !')
                document.querySelector('[name="business"]').focus();
                success = false
            }

            if( success ) {
                for ( const [ key, value ] of Object.entries( errorDatosGenerales ) ) {
                    if(key === "is_incubator"){
                        continue;
                    }
                    if(key === "suitability_exam"){
                        continue;
                    }
                    if ( value.length > 0 && key ){
                        openSnackbarError(`!Verifique los campos! `)
                        success = false
                    }
                }
            }
        }

        return success;
    }

    const makeErrorField = (text) => {
		return (
			<Form.Text
				className="text-danger fw-bold"
				style={{ fontSize: "11px", lineHeight: "0" }}
			>
				{text}
			</Form.Text>
		);
	};

    useEffect(async () => {
        if(candidateData.id) {
            await API.get(`/user/directors_by_prospect/${candidateData.id}`).then(res => {
                let data = res.data.data;
                data.forEach((element, index) => {
                    if(element.selected === 1) {
                        setGrupoComercial(element.id)
                    }
                })
                setGrupoComercialList(data);
            }).catch(error => {
                console.log("cath =>", error)
            })

            await API.get(`/user/advisers_by_prospect/${candidateData.id}`).then(res => {
                let data = res.data.data
                data.forEach((element) => {
                    if(element.selected === 1) {
                        setAsesor(element.id)
                    }
                })
                setAsesorList(data)
            }).catch(error => {
                console.log(error)
            })

            /*listCommercialAdviser.forEach((element, index) => {
                if(element.selected === 1) {
                    setAsesor(index)
                }
            })*/
        }

        setCcbu({candidateData: candidateData, saveDocs: saveDocs, deleteDocs: deleteDocs, isCbu: true})

    }, [candidateData])


    useEffect(() => {
        if(submit) {
            let campos = document.querySelectorAll('form#data_postulant table tbody input, form#data_postulant table tbody select');
            campos.forEach( (campo) => campo.toggleAttribute('disabled'));

            setWorking( true );

            ( async () => {
                await saveDataPersonal()
            })()
        }

    }, [submit])

    const saveDataPersonal = async () => {
        const sendData = JSON.parse(JSON.stringify(datosPersonales))
        if(sendData.gender === ""){
            delete sendData.gender
        }
        //console.log(sendData, "testing data");
        await API.post(`/prospects/update_prospect/${candidateData.id}`, sendData)
        .then( function( response ) {
            openSnackbarSuccess("! Los datos han sido guardados !")
            window.location.reload()
            handleWorkingOut()
        } )
        .catch( function(error) {
            openSnackbarError("Error, no se pudo guardar los datos")
            handleWorkingOut()
        } )
    }

    const handleWorkingOut = () => {

        setSubmit( false )
        setWorking( false )
        toggleEdit()
        let campos = document.querySelectorAll('form#data_postulant table tbody input, form#data_postulant table tbody select');
        campos.forEach( (campo) => campo.setAttribute('disabled', true));
    }

    let grupoComercialName = ""
    let grupoComercialId = ""

    if(grupoComercialList[0] !== "null"){
        {grupoComercialList.forEach(item => {
            if(item.id === grupoComercial){
                grupoComercialId = item.id
                grupoComercialName = item.username
            }
        })}
    }else{

    }
    return (

        <div className="row item" id="container_data_applicant">

            <div className="row header_data_section justify-content-between" role="button" onClick={(e) => setIsOpen(!isOpen)}>
                <span className="w-25"> Datos Personales </span>
                <Button  className="w-auto my-0 py-0 text-white" color="#a3a3a3" onClick={(e) => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faAngleDown} className="float-end" />
                </Button>
            </div>
            <Collapse isOpen={isOpen} className="px-0">
                <Card className="border-top-0">
                    <CardBody>

                        <form action="#" id="data_postulant" onSubmit={ (e) => handleSubmit(e) }>
                            <table className="table table-borderless">
                                <thead>
                                    <tr className="postulant_type">
                                        <th className="w-100 blue_data">Persona {candidateData.physical_person === 1? 'Física': 'Jurídica'}</th>
                                    </tr>
                                    <tr className="referido_info">
                                        <th>
                                            <label className="data_label blue_data" htmlFor="referred">Referido por</label>
                                            <span placeholder="Referido por" disabled name="referred" id="referred" className="data_item blue_data"> {candidateData.referred} </span>
                                        </th>
                                        <th>
                                            {/* TODO: HACER EL DESGLOCE DE ROL PARA EQUIPO COMERCIAL; [USUARIO NORMAL - USUARIO LIDER ] */}
                                            <label className="data_label blue_data" style={{marginLeft: "10px"}} htmlFor="commercial_group">Grupo comercial</label>
                                            {
                                                /** AQUI DEBERIA COMPARAR SI ES USUARIO LIDER O NO. */
                                                grupoComercialList.length > 0 && (
                                                    <select
                                                        onChange={(e) => changeCommercialManager(e.target.value)}
                                                        disabled={ ableToChange() }
                                                        value={ grupoComercial }
                                                        name="commercial_group"
                                                        id="commercial_group"
                                                        className="data_item blue_data"
                                                    >
                                                        <option value="" disabled> Seleccione ... </option>
                                                        {grupoComercialList.map((option, index) => {
                                                            return (
                                                                <option value={option.id} key={index}>
                                                                    {option.username}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                )
                                            }
                                        </th>
                                        <th>
                                            <label className="data_label blue_data" htmlFor="adviser">Asesor </label>
                                            { asesorList.length > 0 && asesorList[0] !== "null"?
                                                <select
                                                    value={ asesor }
                                                    disabled={ ableToChange() }
                                                    onChange={(e) => changeCommercialAdviser(e.target.value)}
                                                    name="adviser"
                                                    id="adviser"
                                                    className="data_item blue_data"
                                                >
                                                    <option value={grupoComercialId}>
                                                        {grupoComercialName}
                                                    </option>
                                                    {asesorList.map((asesor, index) => {
                                                        return (
                                                        <option value={asesor.id} key={index}>
                                                            {asesor.username}
                                                        </option>
                                                        );
                                                    })}
                                                </select>
                                                : <select disabled>
                                                    <option value="" key="">{grupoComercialName}</option>
                                                </select>
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="w-25">
                                            <label className="data_label blue_data" htmlFor="cliente_number">
                                                Número de comitente
                                            </label>
                                            <span className="data_item blue_data">{candidateData.cliente_number}</span>
                                        </th>

                                        <th>
                                            <label className="data_label blue_data" htmlFor="shareolde">
                                                Cuotapartista
                                            </label>
                                            <span className="data_item blue_data" style={{marginRight:"2rem"}}> { candidateData.shareolde} </span>
                                        </th>

                                        <th className='w-25 '>
                                            <label className="data_label blue_data" htmlFor="adviser_is_balanz"> Asesor cuenta Balanz </label>
                                            <span className="data_item blue_data">{candidateData.name_advaisor_is_balanz}</span>
                                        </th>
                                    </tr>
                                    
                                    <tr>

                                        <th className="w-25">
                                            <label className="data_label blue_data" htmlFor="business_unit">
                                                Unidad de negocio
                                            </label>
                                            <span className="data_item blue_data">{candidateData.business_unit}</span>
                                        </th>
                                        <th className="w-25">
                                            <label className="data_label blue_data" htmlFor="business_id">
                                                Id de negocio
                                            </label>
                                            <span className="data_item blue_data">{candidateData.business_id ?? "-"}</span>
                                        </th>

                                        <th>
                                            <label className="data_label blue_data" htmlFor="is_balanz">
                                                ¿Ya es cliente balanz?
                                            </label>
                                            <span className="data_item blue_data"> { candidateData.is_balanz > 0 ? 'SI' : 'NO' } </span>
                                        </th>



                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label className="data_label blue_data" htmlFor="other_relationships">
                                                ¿Tiene alguna otra relación con balanz?
                                            </label>
                                            <p className="data_item blue_data">
                                                {
                                                    candidateData.other_relationships ? `SI ${relationshipsString}` : 'NO'
                                                }
                                            </p>
                                        </th>
                                    </tr>
                                </thead>

                                {/* TBODY DATA PERSONAL CANDIDATE */}
                                <tbody>

                                    {/* PRINT FECHA POSTULACION */}
                                    <PrintRowData label="Fecha Postulación" type="nodata" id="application_date" value={ candidateData.createdAt } />

                                    {(candidateData.physical_person === 1) ? ( /* IF PERSONA FISICA */
                                        CanEditSensityData ? (
                                            <PrintRowData
                                                label="Apellido y Nombre"
                                                type="text"
                                                id="complete_name"
                                                value={datosPersonales.complete_name}
                                                handleChange={handleChangeTableInput}
                                                typeOfPermitido="text"
                                                error={errorDatosGenerales.complete_name}
                                                makeErrorField={makeErrorField}
                                                editable={true} // Añadido para indicar que es editable
                                            />
                                        ) : (
                                            <PrintRowData
                                                label="Apellido y Nombre"
                                                type="nodata"
                                                id="complete_name"
                                                value={candidateData.complete_name}
                                                editable={false} // Añadido para indicar que no es editable
                                            />
                                        )
                                    ) : ( /* ELSE PERSONA JURIDICA */
                                        <>
                                            <PrintRowData label="Razón Social" type="nodata" id="business_name" value={candidateData.business_name} />
                                            <PrintRowData label="Nombre Titular" type="nodata" id="complete_name" value={candidateData.complete_name} />
                                            <PrintRowData label="DNI Titular" type="nodata" id="document" value={candidateData.document} />
                                        </>
                                    )}

                                    {candidateData.physical_person === 1 && /* IF PERSONA FISICA */
                                        <>
                                            <PrintRowData label="Fecha de nacimiento" type="date" id="date_birth" value={ datosPersonales.date_birth } handleChange={ handleChangeTableInput } typeOfPermitido="date" error={ errorDatosGenerales.date_birth } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Lugar de nacimiento" type="text" id="lnaci" value={ datosPersonales.lnaci } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.lnaci } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Nacionalidad" type="text" id="nationality" value={ datosPersonales.nationality } handleChange={ handleChangeTableInput } typeOfPermitido="string" error={ errorDatosGenerales.nationality } makeErrorField={ makeErrorField }  />
                                        </>
                                    }

                                    {(candidateData.physical_person === 1) && /** PERSONA FISICA */
                                        <>
                                            {/* GENDER */}
                                            <tr>
                                                <td>
                                                    <label className="data_label blue_data" htmlFor="gender">Sexo</label>
                                                </td>
                                                <td>
                                                    <select
                                                        onChange={ (e) => handleChangeTableInput(e) }
                                                        value={ datosPersonales.gender }
                                                        name="gender"
                                                        id="gender"
                                                        className="data_item blue_data"
                                                        disabled
                                                    >
                                                        <option value="" disabled> Seleccione ... </option>
                                                        {list.listGender.map((option, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={option.id}
                                                                >
                                                                    {option.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {errorDatosGenerales.gender && makeErrorField(errorDatosGenerales.gender) }
                                                </td>
                                            </tr>

                                            {/* MARITAL STATUS */}
                                            <tr>
                                                <td>
                                                    <label className="data_label blue_data" htmlFor="marital_status">Estado civil</label>
                                                </td>
                                                <td>
                                                    <select
                                                        onChange={ (e) => handleChangeTableInput(e) }
                                                        value={ datosPersonales.marital_status }
                                                        name="marital_status"
                                                        id="marital_status"
                                                        className="data_item blue_data"
                                                        disabled
                                                    >
                                                        <option value="" disabled> Seleccione ... </option>
                                                        {list.listMaritalStatus.map((option, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={option.id}
                                                                >
                                                                    {option.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {errorDatosGenerales.marital_status && makeErrorField(errorDatosGenerales.marital_status) }
                                                </td>
                                            </tr>
                                        </>
                                    }

                                    {candidateData.physical_person === 1 ? ( /* IF PERSONA FISICA */

                                        <>
                                            {/* <PrintRowData label="Ocupación" type="text" id="job" value={ datosPersonales.job } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.job } makeErrorField={ makeErrorField } /> */}

                                            <PrintRowData label="Cargo" type="text" id="job_post" value={ datosPersonales.job_post } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.job_post } makeErrorField={ makeErrorField } />

                                            {/* PHONE 1 PERSONAL */}
                                            <tr className="row_phone">
                                                <td> <label className="data_label blue_data" htmlFor="phone_country_code">Teléfono Particular</label> </td>
                                                <td className="col_phone_number">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            name="phone_country_code"
                                                            id="phone_country_code"
                                                            placeholder="+54"
                                                            value={datosPersonales.phone_country_code}
                                                            onChange={ (e) => handleChangeTableInput(e, 'phone_code_country')}
                                                        />
                                                        {errorDatosGenerales.phone_country_code && makeErrorField(errorDatosGenerales.phone_country_code) }
                                                    </div>

                                                    <div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            name="phone_area"
                                                            id="phone_area"
                                                            placeholder="área"
                                                            value={datosPersonales.phone_area}
                                                            onChange={ (e) => handleChangeTableInput(e, 'int')}
                                                        />
                                                        {errorDatosGenerales.phone_area && makeErrorField(errorDatosGenerales.phone_area) }
                                                    </div>

                                                    <div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            name="phone_number"
                                                            id="phone_number"
                                                            placeholder="teléfono"
                                                            value={datosPersonales.phone_number}
                                                            onChange={ (e) => handleChangeTableInput(e, 'int')}
                                                        />
                                                        {errorDatosGenerales.phone1_number && makeErrorField(errorDatosGenerales.phone1_number) }
                                                    </div>
                                                </td>
                                            </tr>

                                            {/* PHONE 2 MOBILE */}
                                            <tr className="row_phone">
                                                <td> <label className="data_label blue_data" htmlFor="phone2_country_code">Teléfono Móvil</label> </td>
                                                <td className="col_phone_number">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            name="phone2_country_code"
                                                            id="phone2_country_code"
                                                            placeholder="+54"
                                                            value={datosPersonales.phone2_country_code}
                                                            onChange={ (e) => handleChangeTableInput(e, 'phone_code_country')}
                                                        />
                                                        {errorDatosGenerales.phone2_country_code && makeErrorField(errorDatosGenerales.phone2_country_code) }
                                                    </div>

                                                    <div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            name="phone2_area"
                                                            id="phone2_area"
                                                            placeholder="área"
                                                            value={datosPersonales.phone2_area}
                                                            onChange={ (e) => handleChangeTableInput(e, 'int')}
                                                        />
                                                        {errorDatosGenerales.phone2_area && makeErrorField(errorDatosGenerales.phone2_area) }
                                                    </div>

                                                    <div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            name="phone2_number"
                                                            id="phone2_number"
                                                            placeholder="teléfono"
                                                            value={datosPersonales.phone2_number}
                                                            onChange={ (e) => handleChangeTableInput(e, 'int')}
                                                        />
                                                        {errorDatosGenerales.phone2_number && makeErrorField(errorDatosGenerales.phone2_number) }
                                                    </div>
                                                </td>
                                            </tr>

                                            <PrintRowData label="Domicilio" type="text" id="address" value={ datosPersonales.address } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.address } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Código Postal" type="text" id="zip_code" value={ datosPersonales.zip_code } handleChange={ handleChangeTableInput } typeOfPermitido="int" error={ errorDatosGenerales.zip_code } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Localidad" type="text" id="location" value={ datosPersonales.location } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.location } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Provincia" type="text" id="province" value={ datosPersonales.province } handleChange={ handleChangeTableInput } typeOfPermitido="string" error={ errorDatosGenerales.province } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="E-mail" type="text" id="email" value={ datosPersonales.email } handleChange={ handleChangeTableInput } typeOfPermitido="email" error={ errorDatosGenerales.email } makeErrorField={ makeErrorField } />
                                        </>

                                        ):( /* ELSE PERSONA JURIDICA */

                                            <PrintRowData label="Tipo Empresa" type="text" id="business_type" value={ datosPersonales.business_type } />
                                        )
                                    }

                                    {candidateData.physical_person === 1 && /* IF PERSONA FISICA */
                                     CanEditSensityData ? (

                                         <PrintRowData label="D.N.I" type="text" id="document"   handleChange={handleChangeTableInput} value={ datosPersonales.document } typeOfPermitido="int"  error={errorDatosGenerales.document} makeErrorField={makeErrorField}/>
                                     ) :
                                        (
                                            <PrintRowData label="D.N.I" type="nodata" id="document" value={candidateData.document} />
                                        )
                                    }

                                    <PrintRowData label="C.U.I.L. / C.U.I.T. / C.D.I" type="text" id="cuit" value={ datosPersonales.cuit } handleChange={ handleChangeTableInput } typeOfPermitido="int" error={ errorDatosGenerales.cuit } makeErrorField={ makeErrorField } />

                                    {/* POSICION IMPUESTO GANANCIA */}
                                    <tr>
                                        <td> <label className="data_label blue_data" htmlFor="pos_tax_profit">Posición Impuesto Ganancias</label> </td>
                                        <td>
                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.pos_tax_profit }
                                                name="pos_tax_profit"
                                                id="pos_tax_profit"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listPosTaxProfit.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.pos_tax_profit && makeErrorField( errorDatosGenerales.pos_tax_profit ) }
                                        </td>
                                    </tr>

                                    {/* POSICION FRENTE AL IVA */}
                                    <tr>
                                        <td> <label className="data_label blue_data" htmlFor="pos_front_iva">Posición frente al IVA </label> </td>
                                        <td>
                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.pos_front_iva }
                                                name="pos_front_iva"
                                                id="pos_front_iva"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listPostFrontIva.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.pos_front_iva && makeErrorField( errorDatosGenerales.pos_front_iva ) }
                                        </td>
                                    </tr>

                                    {/* AGENTE DE RETENCION */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data" htmlFor="withholding_agent">Agente de retención <small>(R.G. A.F.I.P. 18/97) </small></label>
                                        </td>
                                        <td>

                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.withholding_agent }
                                                name="withholding_agent"
                                                id="withholding_agent"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listToggle.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.withholding_agent && makeErrorField( errorDatosGenerales.withholding_agent ) }
                                        </td>
                                    </tr>

                                    {/* AGENTE DE PERCEPCION */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data" htmlFor="perception_agent">
                                                Agente de percepción <small>(R.G. 3337/91)</small>
                                            </label>
                                        </td>
                                        <td>

                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.perception_agent }
                                                name="perception_agent"
                                                id="perception_agent"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listToggle.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.perception_agent && makeErrorField( errorDatosGenerales.perception_agent ) }
                                        </td>
                                    </tr>

                                    {/* Certificado de no retención y/o exclusión */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data" htmlFor="certificate_non_retention">
                                                Certificado de no retención y/o exclusión
                                            </label>
                                        </td>
                                        <td>

                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.certificate_non_retention }
                                                name="certificate_non_retention"
                                                id="certificate_non_retention"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listToggleOwns.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.certificate_non_retention && makeErrorField( errorDatosGenerales.certificate_non_retention ) }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <label className="data_label blue_data" htmlFor="type_activity">
                                                Tipo de actividad
                                            </label>
                                        </td>
                                        <td>

                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.type_activity }
                                                name="type_activity"
                                                id="type_activity"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                {list.listActivityType.map((option, index) => {
                                                    return (
                                                        <option key={index} disabled={index === 0 ? true: false} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.type_activity && makeErrorField( errorDatosGenerales.type_activity ) }
                                        </td>
                                    </tr>

                                    <PrintRowData label="Industria" type="text" id="business" value={ datosPersonales.business } handleChange={ handleChangeTableInput } typeOfPermitido="string" error={ errorDatosGenerales.business } makeErrorField={ makeErrorField }  />


                                    {/* Situación frente al impuesto a los ingresos brutos */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data small" htmlFor="income_tax_situation">
                                                Situación frente al impuesto a los ingresos brutos
                                            </label>
                                        </td>
                                        <td className="double_field">
                                                <div>
                                                    <select
                                                        onChange={ (e) => handleChangeTableInput(e) }
                                                        value={ datosPersonales.income_tax_situation }
                                                        name="income_tax_situation"
                                                        id="income_tax_situation"
                                                        className="data_item blue_data"
                                                        disabled
                                                    >
                                                        <option value="" disabled> Seleccione ... </option>
                                                        {list.listIncomeTaxSituation.map((option, index) => {
                                                            return (
                                                                <option key={index} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {errorDatosGenerales.income_tax_situation && makeErrorField( errorDatosGenerales.income_tax_situation ) }
                                                </div>

                                                <div>
                                                    <label className="data_label blue_data" htmlFor="registration_number">Nº de inscripción:</label>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={ datosPersonales.registration_number }
                                                            placeholder="Nº de inscripción"
                                                            name="registration_number"
                                                            id="registration_number"
                                                            className="data_item blue_data"
                                                            onChange={ (e) => handleChangeTableInput(e, 'text') }
                                                            disabled
                                                        />
                                                        {errorDatosGenerales.registration_number && makeErrorField( errorDatosGenerales.registration_number ) }
                                                    </div>
                                                </div>
                                        </td>
                                    </tr>

                                    {/* Regimenes de retención en el impuesto a los ingresos brutos. Certificado de no retención y/o exclusión */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data small" htmlFor="regime_retention_income_tax">
                                                Regimenes de retención en el impuesto a los ingresos brutos. Certificado de no retención y/o exclusión
                                            </label>
                                        </td>
                                        <td>

                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.regime_retention_income_tax }
                                                name="regime_retention_income_tax"
                                                id="regime_retention_income_tax"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listToggleOwns.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.regime_retention_income_tax && makeErrorField( errorDatosGenerales.regime_retention_income_tax ) }
                                        </td>
                                    </tr>

                                    {/* Constancia de UIFSO habilitada Vigente */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data small" htmlFor="constancia_uif_so">¿Es Sujeto Obligado ante UIF?</label>
                                        </td>
                                        <td>

                                                <select
                                                    onChange={ (e) => handleChangeTableInput(e) }
                                                    value={ datosPersonales.constancia_uif_so }
                                                    name="constancia_uif_so"
                                                    id="constancia_uif_so"
                                                    className="data_item blue_data"
                                                    disabled
                                                >
                                                    <option value="" disabled> Seleccione ... </option>
                                                    {list.listToggle.map((option, index) => {
                                                        return (
                                                            <option key={index} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                {errorDatosGenerales.constancia_uif_so && makeErrorField( errorDatosGenerales.constancia_uif_so ) }


                                            {/*<div>
                                                <label className="data_label blue_data ddjj_so" htmlFor="ddjj_so">D.D.J.J. de S.O:</label>
                                                <input
                                                    type="checkbox"
                                                    disabled
                                                    name="ddjj_so"
                                                    id="ddjj_so"
                                                    className="data_item blue_data"
                                                    value={ datosPersonales.ddjj_so }
                                                    checked={ datosPersonales.ddjj_so === 1 ? true : false }
                                                    onChange={ (e) => handleChangeTableInput(e) }
                                                />
                                            </div>*/}

                                        </td>
                                    </tr>

                                    {/* Registo de indóneo */}
                                    <tr>
                                        <td><label className="data_label blue_data" htmlFor="suitable">
                                            Registro idóneo
                                        </label></td>
                                        <td>
                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.suitable }
                                                name="suitable"
                                                id="suitable"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value="" disabled> Seleccione ... </option>
                                                {list.listToggle.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.suitable && makeErrorField( errorDatosGenerales.suitable ) }
                                        </td>
                                    </tr>

                                    {/* Es Agente registrado ante la C.N.V. como A.A.G.I. */}
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data small" htmlFor="agente_cnv_aagi_ap">¿Es Agente registrado ante CNV?</label>
                                        </td>
                                        <td className="double_field">
                                            <div>
                                                <select
                                                    onChange={ (e) => { handleChangeTableInput(e) } }
                                                    value={ datosPersonales.agente_cnv_aagi_ap }
                                                    name="agente_cnv_aagi_ap"
                                                    id="agente_cnv_aagi_ap"
                                                    className="data_item blue_data"
                                                    disabled
                                                    style={{width: "50%"}}
                                                >
                                                    <option value="" disabled> Seleccione ... </option>
                                                    {list.listToggle.map((option, index) => {
                                                        return (
                                                            <option key={index} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                {errorDatosGenerales.agente_cnv_aagi_ap && makeErrorField( errorDatosGenerales.agente_cnv_aagi_ap ) }
                                            </div>
                                            { datosPersonales.agente_cnv_aagi_ap !== "" && parseInt(datosPersonales.agente_cnv_aagi_ap) !== 1 &&
                                            <div>
                                                <label className="data_label blue_data" htmlFor="enrollment_aagi">Nº de Matrícula:</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={ datosPersonales.enrollment_aagi }
                                                        onChange={ (e) => handleChangeTableInput(e, 'text') }
                                                        placeholder="Nº de Matrícula"
                                                        name="enrollment_aagi"
                                                        id="enrollment_aagi"
                                                        className="data_item blue_data"
                                                    />
                                                    {errorDatosGenerales.enrollment_aagi && makeErrorField( errorDatosGenerales.enrollment_aagi ) }
                                                </div>
                                            </div>}

                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <label className="data_label blue_data small" htmlFor="pep">
                                             Politicamente Expuesto
                                            </label>
                                        </td>
                                        <td className="double_field">
                                            <div>
                                            <select
                                                onChange={ (e) => handleChangeTableInput(e) }
                                                value={ datosPersonales.pep }
                                                name="pep"
                                                id="pep"
                                                className="data_item blue_data"
                                                disabled
                                            >
                                                <option value={0} disabled> Seleccione ... </option>
                                                {list.listToggle.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errorDatosGenerales.pep && makeErrorField( errorDatosGenerales.pep ) }

                                            </div>

                                            {datosPersonales.pep > 1 && <div>
                                                <label className="data_label blue_data" htmlFor="pep_reasons">Motivos</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={datosPersonales.pep_reasons}
                                                        placeholder="Motivos"
                                                        name="pep_reasons"
                                                        id="pep_reasons"
                                                        className="data_item blue_data"
                                                        onChange={ (e) => {handleChangeTableInput(e, 'text')} }
                                                    />
                                                    {errorDatosGenerales.pep_reasons && makeErrorField( errorDatosGenerales.pep_reasons)}
                                                </div>
                                            </div>}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <label className="data_label blue_data ddjj_so" htmlFor="ddjj_so">Es Incubadora?</label>
                                        </td>
                                        <td style={{width: "10%"}}>
                                            <input
                                                type="checkbox"
                                                disabled
                                                name="is_incubator"
                                                id="is_incubator"
                                                className="data_item blue_data"
                                                value={ datosPersonales.is_incubator }
                                                checked={ datosPersonales.is_incubator === 1 ? true : false }
                                                onChange={ (e) => handleChangeTableInput(e) }/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="data_label blue_data ddjj_so" htmlFor="ddjj_so">Examen de Idoneidad</label>
                                        </td>
                                        <td style={{width: "10%"}}>
                                            <input
                                                type="checkbox"
                                                disabled
                                                name="suitability_exam"
                                                id="suitability_exam"
                                                className="data_item blue_data"
                                                value={ datosPersonales.suitability_exam }
                                                checked={ datosPersonales.suitability_exam === 1 ? true : false }
                                                onChange={ (e) => handleChangeTableInput(e) }/>
                                        </td>
                                    </tr>
                                    {candidateData.prospect_status > 29 &&
                                        <>
                                            {/* DATA BANK */}
                                            <PrintRowData label="Banco" type="text" id="bank_name" value={ datosPersonales.bank_name } handleChange={ handleChangeTableInput } typeOfPermitido="business_name" error={ errorDatosGenerales.bank_name } makeErrorField={ makeErrorField } />

                                            {ccbu !== null && <PrintRowData disabled={!checkStatus} deleteCBU={checkStatus} label="CBU" type="text" id="cbu" value={ datosPersonales.cbu } handleChange={ handleChangeTableInput } typeOfPermitido="int" error={ errorDatosGenerales.cbu } makeErrorField={ makeErrorField } cbu={ccbu}/>}

                                            {/* TYPE ACCOUNT */}
                                            <tr>
                                                <td>
                                                    <label className="data_label blue_data" htmlFor="account_type">Tipo de cuenta bancaria</label>
                                                </td>
                                                <td>
                                                    <select
                                                        onChange={ (e) => handleChangeTableInput(e) }
                                                        value={ datosPersonales.account_type }
                                                        name="account_type"
                                                        id="account_type"
                                                        className="data_item blue_data"
                                                        disabled
                                                    >
                                                        <option value="" disabled> Seleccione ... </option>
                                                        {list.listTypeAccount.map((option, index) => {
                                                            return (
                                                                <option key={index} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {errorDatosGenerales.account_type && makeErrorField( errorDatosGenerales.account_type ) }
                                                </td>
                                            </tr>

                                            {/* DATA RRSS */}
                                            <PrintRowData label="Facebook" type="text" id="rrss_fb" value={ datosPersonales.rrss_fb } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.rrss_fb } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Linkedin" type="text" id="rrss_lk" value={ datosPersonales.rrss_lk } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.rrss_lk } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Twitter" type="text" id="rrss_tw" value={ datosPersonales.rrss_tw } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.rrss_tw } makeErrorField={ makeErrorField } />

                                            <PrintRowData label="Instagram" type="text" id="rrss_ig" value={ datosPersonales.rrss_ig } handleChange={ handleChangeTableInput } typeOfPermitido="text" error={ errorDatosGenerales.rrss_ig } makeErrorField={ makeErrorField } />
                                        </>
                                    }

                                    {/* CV Y BOTONES */}
                                    <tr className="tr_button_cv my-4 border-0 bg-white">

                                        <td className="hidden" id="buttons_actions_form">
                                            <button
                                                type="submit"
                                                name="submit_data_personal"
                                                id="submit_data_personal"
                                                className="btn blue_btn balanz_btn"
                                                disabled={ working }
                                            >
                                                {working ? '...' : 'Guardar'}
                                            </button>

                                            <button
                                                type="button"
                                                name="cancel_data_personal"
                                                id="cancel_data_personal"
                                                className="btn red_btn balanz_btn ms-4"
                                                disabled={ working }
                                                onClick={ toggleEdit }
                                            >Cancelar</button>
                                        </td>
                                    </tr>

                                    <tr className="tr_button_cv my-4 border-0 bg-white">

                                                { candidateData.cv_id > 0 ?
                                                    <div>
                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${candidateData.cv_id}`} target="_blank" className="btn gray_btn button_cv balanz_btn_longx"> VER CV</a>
                                                        {checkStatus && (
                                                            <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(candidateData.cv_id)}}/>
                                                        )}

                                                    </div>
                                                    :
                                                    <div>
                                                        <button disabled={!checkStatus} type="button" onClick={ () =>
                                                            {document.querySelector("#cv_id").click()}}
                                                            className="btn blue_btn balanz_btn_longx">
                                                            Cargar CV
                                                            <input type="file" disabled={!checkStatus} id="cv_id" onChange={(e)=>{saveDocs(e, "cv")}} hidden/>
                                                        </button>
                                                    </div>
                                                }


                                                {
                                                    candidateData.document_front === 0 ? (
                                                    <div>
                                                        <input type="file" id='front' disabled={!checkStatus} name='document_front' onChange={e => {uploadFrontDoc(e)}} hidden />
                                                        <button disabled={!checkStatus} type="button" onClick={() => {document.getElementById("front").click()}} className="btn blue_btn balanz_btn_longx">CARGAR DNI FRENTE</button>
                                                    </div>) :
                                                    <div>
                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${candidateData.document_front}`} target="_blank" className="btn gray_btn button_cv balanz_btn_longx">VER DNI FRENTE</a>
                                                        {checkStatus && (
                                                            <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(candidateData.document_front)}}/>
                                                        )}
                                                    </div>
                                                }


                                                {
                                                    candidateData.document_back === 0 ? (
                                                    <div style={{marginLeft: "30px",}}>
                                                        <input type="file" id='back' disabled={!checkStatus} name='document_back' onChange={e => {uploadBackDoc(e)}} hidden />
                                                        <button disabled={!checkStatus} type="button" onClick={() => {document.getElementById("back").click()}} className="btn blue_btn balanz_btn_longx">CARGAR DNI DORSO</button>
                                                    </div>) :
                                                    <div>
                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${candidateData.document_back}`} target="_blank" className="btn gray_btn button_cv balanz_btn_longx">VER DNI DORSO</a>
                                                        {checkStatus && (
                                                            <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(candidateData.document_back)}}/>
                                                        )}

                                                    </div>

                                                }


                                    </tr>

                                    {
                                        /*
                                        @TODO: Cambiar los roles por grupos cuando se actualice el repositorio
                                        */
                                    }
                                    {

                                            <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_doc_varios') && (
                                                <>
                                                    {
                                                        candidateData.btn_doc_varios.length === 0 ?
                                                        <div>
                                                            <button  type="button" onClick={ () =>
                                                                {document.querySelector("#doc_varios").click()}}
                                                                className="btn blue_btn balanz_btn_longx"
                                                                disabled={!checkDocument}
                                                                >
                                                                Doc Varios

                                                                <input  disabled={!checkDocument} type="file" multiple id="doc_varios" onChange={(e)=>{saveDocs(e, "doc_varios")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button  type="button" onClick={ () =>
                                                                {document.querySelector("#doc_varios").click()}}
                                                                className="btn blue_btn balanz_btn_longx"
                                                                disabled={!checkDocument}
                                                                >
                                                                Doc Varios

                                                                <input   disabled={!checkDocument} type="file" multiple id="doc_varios" onChange={(e)=>{saveDocs(e, "doc_varios")}} hidden/>
                                                            </button>
                                                            {candidateData.btn_doc_varios.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_doc_varios[index]} - {candidateData.name_doc_varios[index]}</a>
                                                                        {<FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>

                                                            })}

                                                        </div>
                                                    }
                                                </>
                                            )}
                                            </td>
                                        </tr>


                                    }
                                   
                                    {//candidateData.prospect_status > 29 &&
                                        (userData.role_id === 3 || userData.role_id === 4) &&
                                        <>
                                        {candidateData.cliente_number > 0 &&
                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                                <button type="button" onClick={() => {window.open(LEGAJO_COMITENTE_URL.concat(candidateData.cliente_number), "_blank")}}
                                                className="btn blue_btn balanz_btn_longx">VER LEGAJO <br/> COMITENTE</button>
                                            </td>
                                        </tr>}
                                        {candidateData.shareolde > 0 &&
                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                                <button type="button" onClick={() => {window.open(LEGAJO_CUOTAPARTISTA_URL.concat(candidateData.shareolde), "_blank")}}
                                                className="btn blue_btn balanz_btn_longx">VER LEGAJO CUOTAPARTISTA</button>
                                            </td>
                                        </tr>}
                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_google_doc') && (
                                                <>
                                                    {
                                                        candidateData.btn_google_doc.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#google_doc").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Documentos de Google

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="google_doc" onChange={(e)=>{saveDocs(e, "google_doc")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#google_doc").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Documentos de Google

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="google_doc" onChange={(e)=>{saveDocs(e, "google_doc")}} hidden/>
                                                            </button>
                                                            {candidateData.btn_google_doc.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_google_doc[index]} - {candidateData.name_google_doc[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>

                                                            })}

                                                        </div>
                                                    }
                                                </>
                                            )}
                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_world_check') && (
                                                <>
                                                    {
                                                        candidateData.btn_world_check.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#world_check").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                World check one

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="world_check" onChange={(e)=>{saveDocs(e, "world_check")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#world_check").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                World check one

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="world_check" onChange={(e)=>{saveDocs(e, "world_check")}} hidden/>
                                                            </button>

                                                            {candidateData.btn_world_check.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_world_check[index]} - {candidateData.name_world_check[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                </>
                                            )}

                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_afip') && (
                                                <>
                                                    {
                                                        candidateData.btn_afip.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#AFIP").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                AFIP

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="AFIP" onChange={(e)=>{saveDocs(e, "afip")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#AFIP").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                AFIP

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="AFIP" onChange={(e)=>{saveDocs(e, "afip")}} hidden/>
                                                            </button>
                                                            {candidateData.btn_afip.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_afip[index]} - {candidateData.name_afip[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                </>
                                            )}

                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_uif') && (
                                                <>
                                                    {
                                                        candidateData.btn_uif.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#UIF").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                UIF
                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="UIF" onChange={(e)=>{saveDocs(e, "uif")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#UIF").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                UIF
                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="UIF" onChange={(e)=>{saveDocs(e, "uif")}} hidden/>
                                                            </button>
                                                            {candidateData.btn_uif.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_uif[index]} - {candidateData.name_uif[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>

                                                    }
                                                </>
                                            )}

                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_nosis') && (
                                                <>
                                                    {
                                                        candidateData.btn_nosis.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#nosis").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Nosis

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="nosis" onChange={(e)=>{saveDocs(e, "nosis")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#nosis").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Nosis

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="nosis" onChange={(e)=>{saveDocs(e, "nosis")}} hidden/>
                                                            </button>
                                                            {candidateData.btn_nosis.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_nosis[index]} - {candidateData.name_nosis[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>

                                                    }
                                                </>
                                            )}
                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                                {candidateData.hasOwnProperty('btn_additional_info') && (
                                                    <>
                                                        {
                                                            candidateData.btn_additional_info.length === 0 ?
                                                            <div>
                                                                <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                    {document.querySelector("#AdditionalInfo").click()}}
                                                                    className="btn blue_btn balanz_btn_longx">
                                                                    Informacion Adicional

                                                                    <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="AdditionalInfo" onChange={(e)=>{saveDocs(e, "additional_info")}} hidden/>
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className="file-view-data">
                                                                <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                    {document.querySelector("#AdditionalInfo").click()}}
                                                                    className="btn blue_btn balanz_btn_longx">
                                                                    Informacion Adicional

                                                                    <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="AdditionalInfo" onChange={(e)=>{saveDocs(e, "additional_info")}} hidden/>
                                                                </button>
                                                                {candidateData.btn_additional_info.map((option, index) => {
                                                                    return <div className='d-block mt-3' key={option}>
                                                                        <div className="d-flex align-items-center">
                                                                            <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_additional_info[index]} - {candidateData.name_additional_info[index]}</a>
                                                                            {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_compliance_report') && (
                                                <>
                                                    {
                                                        candidateData.btn_compliance_report.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#ComplianceReport").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Informe Compliance

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="ComplianceReport" onChange={(e) => {saveDocs(e, "compliance_report")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#ComplianceReport").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Informe Compliance

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="ComplianceReport" onChange={(e) => {saveDocs(e, "compliance_report")}} hidden/>
                                                            </button>
                                                            {candidateData.btn_compliance_report.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_compliance_report[index]} - {candidateData.name_compliance_report[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                </>
                                            )}
                                            </td>
                                        </tr>

                                        <tr className="tr_button_cv my-4 border-0 bg-white">
                                            <td className="w-100">
                                            {candidateData.hasOwnProperty('btn_mac') && (
                                                <>
                                                    {
                                                        candidateData.btn_mac.length === 0 ?
                                                        <div>
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#MAC").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Mandato de Administración de Cartera

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="MAC" onChange={(e)=>{saveDocs(e, "mac")}} hidden/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="file-view-data">
                                                            <button disabled={candidateData.prospect_status !== 50} type="button" onClick={ () =>
                                                                {document.querySelector("#MAC").click()}}
                                                                className="btn blue_btn balanz_btn_longx">
                                                                Mandato de Administración de Cartera

                                                                <input disabled={candidateData.prospect_status !== 50} type="file" multiple id="MAC" onChange={(e)=>{saveDocs(e, "mac")}} hidden/>
                                                            </button>

                                                            {candidateData.btn_mac.map((option, index) => {
                                                                return <div className='d-block mt-3' key={option}>
                                                                    <div className="d-flex align-items-center">
                                                                        <a href={API_URL+`/prospects/${candidateData.id}/file/${option}`} target="_blank" className="text-left d-block">{candidateData.date_mac[index]} - {candidateData.name_mac[index]}</a>
                                                                        {candidateData.prospect_status === 50 && <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(option)}}/>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                </>
                                            )}


                                            </td>
                                        </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                        </form>

                        {candidateData.prospect_status > 29 &&
                            <KnowingIfa user_role={userData.role_id} checkData={knowingIfaData}/>
                        }

                    </CardBody>
                </Card>
            </Collapse>

            <Modal isOpen={PdfModalOpen} id="modal_cv_pdf">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={e => setPdfModalOpen(false)} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}
                    >
                        {
                            Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ),
                            )
                        }
                    </Document>
                </ModalBody>
            </Modal>

            <Modal isOpen={PdfModalOpen} id="modal_cv_pdf">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={e => setPdfModalOpen(false)} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}
                    >
                        {
                            Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ),
                            )
                        }
                    </Document>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalInfo} id="modal_info">
                <ModalHeader className="headerModal"
                    toggle={() => {setModalInfo(false);
                                    setLastValueAsesor("");
                                    setLastValueGrupoComercial("")}}>
                        <div className="modalHeadTilte">
                            BALANZ IFA
                        </div>
                </ModalHeader>
                <ModalBody>
                    {modalText}
                </ModalBody>
                <ModalFooter style={{display: "flex", justifyContent:"center"}}>
                    <div>
                        {lastValueGrupoComercial &&
                            <Button
                                style={{margin:"0 10px", width:"120px"}}
                                variant="success"
                                onClick={confirmGrupoComercial}
                            >Aceptar</Button>
                        }

                        {lastValueAsesor &&
                            <Button
                            style={{margin:"0 10px", width:"120px"}}
                            variant="success"
                            onClick={confirmAsesor}
                            >Aceptar</Button>
                        }
                        <Button onClick={() => {setModalInfo(false); setLastValueAsesor(""); setLastValueGrupoComercial("")}}
                            style={{margin:"0 10px", width:"120px"}}
                                variant="danger">Rechazar
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>

    )
}
