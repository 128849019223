import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { checkProspectStatus } from '../../../hooks/commons'
import { GROUP } from '../../../constants'
export const BannerSection = ({user_role, prospect_status}) => {
    const handleBack = () => {
        window.history.back();
    }
    const CanEditSensityData = (GROUP.EQ_SOPORTE.includes(user_role) )
    const toggleEdit = () => {
        const status = prospect_status;
        let campos = document.querySelectorAll('form#data_postulant table tbody input, form#data_postulant table tbody select')
        if(CanEditSensityData && [100, 102].includes(status)) {
            campos = document.querySelectorAll('form#data_postulant table tbody input#email')
        }
        campos.forEach( (campo) => campo.toggleAttribute('disabled'))

       
        let buttos_action = document.querySelector('#buttons_actions_form');
        buttos_action.classList.toggle('hidden')

        let button_edit = document.querySelector('#edit');
        button_edit.classList.toggle('hidden')
    }


    const setSeeAble = () => {
        const status = prospect_status;
        const show = 'btn blue_btn balanz_btn_long';
        const none = 'd-none';
        let display_btn = none;

        if(checkProspectStatus(status, user_role)) {
            display_btn = show;
        }
        return display_btn
    }

    return (
        
         <div className="row banner_section mt-4">
            <div className="col-md">
                <span className="title_section blue_title">Nuevo Postulante al IFA </span>
            </div>
            <div className="col-md">
                <button href="#back" onClick={ (e)=>handleBack() } className="arrow_back gray_text border-0 bg-transparent">
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <span><b>volver al listado</b></span>
                </button>
                {user_role !== 3 &&
                    <button type="button" id="edit" className={ setSeeAble() } onClick={ toggleEdit }>
                        <span>editar datos</span>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                }

            </div>
        </div>
    )
}
