import {
    GRUPO_EQ_COMERCIAL,
    ROLE_COMPLIANCE,
    ROLE_SOP_COMERCIAL,
    GROUP
} from "../constants";

export const checkProspectStatus = (status, user_role) => {
    if (status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
        return false
    } else if (GROUP.EQ_DIRECTORES.includes(user_role) && status >= 1 && status <= 30) {
        return true
    } else if (GROUP.EQ_SOPORTE.includes(user_role) && status > 29 && status <= 40) {
        return true
    } else if (GROUP.COMPLIANCE.includes(user_role) && status > 39 && status <= 50) {
        return true
    } else if(GROUP.EQ_SOPORTE.includes(user_role) && [100, 102].includes(status)) {
        return true
    } else {
        return false
    }
}

